import { Grid } from "./s25.virtual.grid.component";
import { S25Util } from "../../util/s25-util";
import { DowGrid } from "./s25.dow.grid.component";
import { StandardSchedule } from "../../services/standard.schedule.service";
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";

export namespace DowGridUtil {
    export function getItemPosition(
        itemData: { startHour: number; endHour: number },
        totalColumns: number,
        index: number,
    ) {
        if (!totalColumns) return { left: 0, width: 0 };
        // DOW index + hour index = column index
        const left = ((index + itemData.startHour) / totalColumns) * 100;
        let hours = itemData.endHour - itemData.startHour;
        if (hours < 0) hours += 24; // Item spans midnight
        const width = (hours / totalColumns) * 100;
        return { left, width };
    }

    export function getHeaders<HeaderData extends Grid.CustomData>(
        visibleDows: string[],
        dows: string[],
        startHour: number,
        endHour: number,
        is24Hours: boolean,
        spanHours: number,
    ): Grid.Header<HeaderData>[] {
        const headers: Grid.Header<HeaderData>[] = [];
        const visible = new Set(visibleDows);
        for (let dow of dows) {
            const dowHeader: Grid.Header<HeaderData> = {
                id: dow,
                heading: dow,
                data: {} as HeaderData,
                subHeaders: [],
                hidden: !visible.has(dow),
                truncateOverflow: true,
            };
            for (let hour = 0; hour < 24; hour++) {
                const start = S25Util.date.toTimeStrFromHours(hour, is24Hours);
                const heading = hour % spanHours === 0 ? `${start}` : "";
                dowHeader.subHeaders.push({
                    id: `${dow}-${hour}`,
                    heading,
                    data: {} as HeaderData,
                    hidden: hour < startHour || hour >= endHour,
                });
            }
            headers.push(dowHeader);
        }

        return headers;
    }

    export function getTimesFromPosition(item: { left: number; width: number }, dows: string[]) {
        const columnCount = 24 * dows.length;

        // Figure out new dow, based on left
        const column = (item.left / 100) * columnCount;
        const dowIndex = S25Util.clamp(Math.floor(column / 24), 0, dows.length - 1);
        const dow = dows[dowIndex];
        // Figure out new start time, based on left and dow
        const startHour = column % 24;
        // Figure out new end time, based on start and width
        const endHour = (startHour + (item.width / 100) * columnCount) % 24;

        return { dow, startHour, endHour };
    }

    export function scheduleChecker(schedule: StandardSchedule[]) {
        const byDow = S25Util.array.groupBy(schedule, (s) => s.dow);
        return function (item: DowGrid.Item<DowGrid._CustomItemData<DowGrid.CustomItemData>>) {
            if (item.data._isStandardSchedule) return true;
            const [start, end] = [Math.round(item.data.startHour * 60), Math.round(item.data.endHour * 60)];
            const schedules = byDow[item.data.dow] || [];
            for (const schedule of schedules) {
                if (schedule.start_time === start && schedule.end_time === end) return true;
            }
            return false;
        };
    }

    export function getStandardScheduleItems<ItemData extends DowGrid.CustomItemData>(
        standardSchedule: StandardSchedule[],
    ): DowGrid.Item<DowGrid._CustomItemData<ItemData>>[] {
        return (standardSchedule || []).map((schedule) => {
            return {
                id: `standard-schedule=${schedule.id}`,
                top: -1, // Make sure it goes all the way to the top edge
                height: 102, // Make sure it goes all the way to the bottom edge
                draggable: false,
                noInteraction: true,
                data: {
                    dow: schedule.dow,
                    noShadows: true,
                    startHour: schedule.start_time / 60,
                    endHour: schedule.end_time / 60,
                    _isStandardSchedule: true,
                    _fitsSchedule: true,
                } as DowGrid._CustomItemData<ItemData>,
            };
        });
    }

    export function generateShadows<ItemData extends DowGrid.CustomItemData>(
        item: Grid._Item<DowGrid._CustomItemData<ItemData>>,
        dowsList: string[],
        dowColumnIndex: Map<string, number>,
        is24Hours: boolean,
    ) {
        if (item.data._isStandardSchedule) return [];
        const { dow } = item.data;

        const shadows: Grid.Item<DowGrid._CustomItemData<ItemData>>[] = [];
        const dows = dowsList.filter((d) => d !== dow);
        const days = new Set(dow.split("") as EventSummary.DowChar[]);
        for (let newDow of dows) {
            if (!newDow.split("").some((day: EventSummary.DowChar) => days.has(day))) continue; // No intersection

            const shadow: Grid._Item<DowGrid._CustomItemData<ItemData>> = {
                ...item, // Copy original item to shadow
                id: `${item.id}-shadow-${newDow}`,
                draggable: false, // Shadows are not draggable
                data: { ...item.data } as unknown as DowGrid._CustomItemData<ItemData>, // Copy data to shadow
                overlapping: [],
            };
            shadow.data._isShadow = true;
            shadow.data.dow = newDow;
            shadow.data._realItem = item;
            shadow.data._fitsSchedule = true;
            if ("name" in shadow.data) shadow.data.name = `${dow} - ${shadow.data.name}`; // Prefix name with original DOW
            shadow.ariaLabel = getAriaLabel(shadow, is24Hours);
            shadow.linkedItems.add(shadow.id); // Add shadow to shared set of linked items
            Object.assign(shadow, getItemPosition(shadow.data, dowsList.length * 24, dowColumnIndex.get(newDow))); // Assign new left and
            // width values
            delete shadow._gridData; // Make sure shadow doesn't have same grid data object

            shadows.push(shadow);
        }

        return shadows;
    }

    export function getAriaLabel<ItemData extends DowGrid._CustomItemData<DowGrid.CustomItemData>>(
        item: DowGrid.Item<ItemData>,
        is24Hours: boolean,
    ) {
        const startTime = S25Util.date.ariaTimeString(S25Util.date.toTimeStrFromHours(item.data.startHour, is24Hours));
        const endTime = S25Util.date.ariaTimeString(S25Util.date.toTimeStrFromHours(item.data.endHour, is24Hours));
        return `${item.data.dow}, ${startTime} to ${endTime}`;
    }
}
