import { NgModule } from "@angular/core";
import { S25VirtualGridComponent } from "./s25.virtual.grid.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { CommonModule } from "@angular/common";
import { PopoverModule } from "../s25-popover/popover.module";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25VirtualGridAbstractItem } from "./s25.virtual.grid.abstract.item";
import { S25DowGridComponent } from "./s25.dow.grid.component";
import { S25OfficeHoursSliderModule } from "../s25-office-hours-slider/s25.office.hours.slider.module";
import { S25ScrollMinimapComponent } from "../../standalone/s25-scroll-minimap/s25.scroll.minimap.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25ButtonComponent } from "../../standalone/s25.button.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";

@NgModule({
    imports: [
        S25LoadingInlineModule,
        S25ItemModule,
        CommonModule,
        S25ScrollMinimapComponent,
        PopoverModule,
        S25IconModule,
        S25CheckboxModule,
        S25OfficeHoursSliderModule,
        S25MultiselectModule,
        S25ButtonComponent,
        S25ModalModule,
        S25DropdownPaginatedModule,
    ],
    exports: [S25VirtualGridComponent, S25VirtualGridAbstractItem, S25DowGridComponent],
    declarations: [S25VirtualGridComponent, S25VirtualGridAbstractItem, S25DowGridComponent],
    providers: [],
})
export class S25VirtualGridModule {}
